import { Box, Button } from '@mui/material';
import { ModalBase } from '../modal-base';
import { useState } from 'react';

type Props = {
  onClick: () => void;
};

export const DeleteButton = ({ onClick }: Props) => {
  const [confirm, setConfirm] = useState(false);
  const handleDelete = () => {
    onClick();
    setConfirm(false);
  };

  return (
    <>
      <ModalBase title={'Are you sure you want to delete?'} open={confirm} onClose={() => []}>
        <Box mt={8} width={1} display={'flex'} justifyContent={'space-between'}>
          <Button onClick={() => setConfirm(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleDelete}>
            Confirm
          </Button>
        </Box>
      </ModalBase>
      <Button onClick={() => setConfirm(true)}>Delete</Button>
    </>
  );
};
