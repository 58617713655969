import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LineChart } from '@mui/x-charts';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { Attendance } from 'hooks/useAttendance';
import { useClass } from 'hooks/useClass';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { groupBy, keyBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { chartPalette } from 'Theme';
import { iso } from 'utils/time';

type Props = {
  attendance: Attendance[];
};

export const Body = ({ attendance }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const { classes } = useClass(school.id);

  const classMap = keyBy(classes, 'id');

  const groups = useMemo(
    () =>
      groupBy(
        attendance.map(a => ({ ...a, class: classMap[a.classId] })),
        a => a.class?.subject[0]?.name
      ),
    [classes, classMap]
  );

  const dates = new Array(12).fill(0).map((_, i) => DateTime.now().minus({ weeks: i }));

  const buildData = (att: Attendance[]) =>
    dates.map(date => {
      return att.filter(d => {
        const attDate = iso(d.date).toSeconds();
        return attDate <= date.endOf('day').toSeconds() && attDate > date.minus({ weeks: 1 }).toSeconds();
      }).length;
    });

  const total = {
    label: 'Total',
    data: buildData(attendance),
    showMark: false,
  };

  const series = [
    total,
    ...Object.entries(groups).map(k => ({
      label: k[0].length > 25 ? `${k[0].substring(0, 25)}...` : k[0],
      data: buildData(k[1]),
      showMark: false,
      style: { stroke: school.priColor },
    })),
  ];

  return (
    <CardBase sx={{ p: 3, height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
      <Box mt={1}>
        <Typography mb={1} color={grey[600]}>
          {'Attendance'}
        </Typography>
        <LineChart
          dataset={dates.map(d => ({ date: d.toISO() }))}
          yAxis={[
            {
              scaleType: 'linear',
            },
          ]}
          xAxis={[
            {
              reverse: true,
              scaleType: 'band',
              dataKey: 'date',
              valueFormatter: (v: string) =>
                iso(v).toLocaleString({
                  month: 'short',
                  day: '2-digit',
                }),
            },
          ]}
          height={260}
          sx={{ pr: 0, '&&': { touchAction: 'none', MozWindowDragging: 'drag' } }}
          series={series}
          margin={{
            left: 40,
            right: 10,
            top: 95,
            bottom: 20,
          }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 10,
              },
              itemMarkWidth: 8,
              itemMarkHeight: 8,
              markGap: 5,
              itemGap: 15,
              position: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
          }}
          colors={[school.priColor, ...chartPalette]}
        />
      </Box>
    </CardBase>
  );
};
