import { School } from 'hooks/useSchool';

export const loadManifest = (school?: School, uri = '') => {
  const logo = `${process.env.REACT_APP_BASE_URL}/mm-logo.svg`;
  const name = `${school ? school.name : 'Martial Manager'}`;

  const manifest = {
    short_name: name,
    name: name,
    id: name,
    icons: [
      {
        src: school?.logo ? school.logo : logo,
        sizes: 'any',
        purpose: 'any',
      },
    ],
    start_url: `${document.location.origin}/${uri}`,
    display: 'standalone',
    scope: `${document.location.origin}/`,
    theme_color: '#000000',
    background_color: '#ffffff',
    version: '0.9.01',
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);

  document.querySelector('#manifest')?.remove();

  const manifestLink = document.createElement('link');
  manifestLink.rel = 'manifest';
  manifestLink.href = manifestURL;
  manifestLink.setAttribute('id', 'manifest');
  document.head.appendChild(manifestLink);
};
