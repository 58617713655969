import { ButtonBase } from '@mui/material';
import { useQueryState } from 'hooks/useQueryState';
import { PageType, Tabs } from 'utils/types';

export const MemberSelectButton = ({ userId, children }: { userId: number; children: JSX.Element }) => {
  const [, setQuery] = useQueryState();

  return (
    <ButtonBase
      sx={{
        display: 'inline-block',
        textAlign: 'left',
        scrollSnapAlign: 'center',
      }}
      onClick={() =>
        setQuery({
          page: PageType.member_settings,
          [`${PageType.member_settings} tab`]: Tabs[PageType.member_settings][0],
          userId: `${userId}`,
        })
      }
    >
      {children}
    </ButtonBase>
  );
};
