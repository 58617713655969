import { Grid, Box, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useSchoolContext } from 'components/context/school-context';
import { MembershipCard } from 'components/shared/membership-card/membership-card';
import { useMembership } from 'hooks/useMemership';
import { CustomModal } from 'components/shared/custom-modal';
import { User } from 'hooks/useUser';
import { useMutate } from 'hooks/useMutate';
import { UserDetailsModal } from '../user-details-modal';
import { CreateUserDto } from 'utils/types';
import { useUserContext } from 'components/context/user-context';

type Props = {
  open: boolean;
  user: User;
  admin?: boolean;
  onClose: () => void;
};

export const EnrolmentModal = ({ open, user, admin, onClose }: Props) => {
  const { school } = useSchoolContext();
  const { user: contextUser } = useUserContext();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const availableMemberships = useMemo(() => useMembershipResponse.data, [useMembershipResponse.data]);

  const [enrolUser, setEnrolUser] = useState<{ user: User; membershipId: number }>();
  const { create, response } = useMutate<string>('enrolment', {
    invalidate: ['enrolment'],
    onSuccess: () => setEnrolUser(undefined),
    headers: { href: location.href, ...(admin && { impersonator: contextUser?.id }) },
  });
  const { update } = useMutate('user', {
    ...(admin && { headers: { impersonator: contextUser?.id } }),
    invalidate: ['user'],
  });

  useEffect(() => {
    if (!!response) {
      window.location.assign(response);
    }
  }, [response]);

  const handleSubmit = (data?: CreateUserDto) => {
    if (data) {
      update(data);
    }
    if (enrolUser) {
      create({ userId: enrolUser.user.id, membershipId: enrolUser.membershipId });
    }
  };

  return (
    <>
      {enrolUser && (
        <UserDetailsModal user={enrolUser.user} onSubmit={handleSubmit} onClose={() => setEnrolUser(undefined)} />
      )}
      <CustomModal open={open} onClose={onClose}>
        <Grid container direction={'column'} m={'auto'}>
          <Grid container justifyContent="center">
            <Box
              sx={{
                overflow: 'scroll',
                display: 'flex',
                scrollSnapType: 'x mandatory',
              }}
              ref={ref}
              onMouseDown={onMouseDown}
            >
              {availableMemberships?.length ? (
                availableMemberships.map(m => (
                  <MembershipCard membership={m} key={m.id}>
                    <Box textAlign={'center'}>
                      <Button variant="contained" onClick={() => setEnrolUser({ user, membershipId: m.id })}>
                        Sign up
                      </Button>
                    </Box>
                  </MembershipCard>
                ))
              ) : (
                <Typography variant="h2" mb={4}>
                  No active memberships
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};
