import { useToken } from 'hooks/useToken';
import { createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Kiosk, useKiosk } from 'hooks/useKiosk';
import { createCustomTheme } from 'Theme';
import { ThemeProvider } from '@mui/material';
import { loadManifest } from 'setup-manifest';

type KioskAttributes = {
  kiosk: Kiosk;
  priColor: string;
  secColor: string;
};

const KioskContext = createContext<KioskAttributes | undefined>(undefined);

export const useKioskContext = () => useContext(KioskContext) as KioskAttributes;

interface Props {
  children: JSX.Element;
}

const agent = navigator.userAgent.split(/\;|\)/g)[1];

export const KioskContextProvider = ({ children }: Props) => {
  const { uuid } = useParams();
  const token = useToken();
  const { kiosk, isLoading } = useKiosk(token, uuid, agent);

  useEffect(() => {
    if (!isLoading && kiosk) {
      loadManifest(kiosk.school, `kiosk/${kiosk.uuid}`);
    }
  }, [kiosk, isLoading]);

  if (!kiosk) {
    return <>Kiosk is bound to another device</>;
  }

  const theme = createCustomTheme(kiosk.school.priColor, kiosk.school.secColor, kiosk.school.terColor);

  return (
    <ThemeProvider theme={theme}>
      <KioskContext.Provider
        value={{
          kiosk,
          priColor: kiosk.school.priColor,
          secColor: kiosk.school.secColor,
        }}
      >
        {children}
      </KioskContext.Provider>
    </ThemeProvider>
  );
};
