import { Box, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { DisplayImage } from 'components/shared/display-image';
import { CardBase } from 'components/shared/card-base';
import { User } from 'hooks/useUser';
import { useSchoolContext } from 'components/context/school-context';
import { Enrolment, Member } from 'hooks/useMember';
import { EnrolmentModal } from 'components/modals/enrolment-modal';
import { AddButton } from 'components/shared/add-button';
import { isActive } from 'utils/types';
import { EnrolmentCard } from 'components/shared/enrolment-card';
import { UpdateButton } from 'components/shared/enrolment-card/update-button';
import { keyBy } from 'lodash';

const UserCard = ({
  user,
  member,
  groupEnrolments = [],
}: {
  user: User;
  member?: Member;
  groupEnrolments: Enrolment[];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <EnrolmentModal open={open} user={user} onClose={() => setOpen(false)} />
      <CardBase sx={{ m: 4, mt: 10, height: 'fit-content' }}>
        <Box width={1} boxSizing={'border-box'}>
          <Box display={'flex'} p={4}>
            <DisplayImage picture={user.picture} firstName={user.firstName} size={50} />
            <Box p={2}>
              <Typography
                variant="h2"
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                maxWidth={'75%'}
                textAlign={'left'}
              >{`${user.firstName} ${user.lastName}`}</Typography>
            </Box>
          </Box>
          <AddButton label="Add membership" onClick={() => setOpen(v => !v)} sx={{ mx: 4, mb: 4, maxWidth: 700 }} />
          <Box>
            {member?.enrolments
              .filter(e => isActive(e.status))
              .map((e, i) => (
                <Box key={i} px={4} pb={4}>
                  <EnrolmentCard user={user} enrolment={e} button={<UpdateButton enrolment={e} user={user} />} />
                </Box>
              ))}
          </Box>
          <Box key={groupEnrolments.length}>
            {member &&
              groupEnrolments
                .filter(e => isActive(e.status))
                .map((e, i) => (
                  <Box key={i} px={4} pb={4}>
                    <EnrolmentCard user={user} enrolment={e} button={<UpdateButton enrolment={e} user={user} />} />
                  </Box>
                ))}
          </Box>
        </Box>
      </CardBase>
    </>
  );
};

export const Memberships = () => {
  const { member } = useSchoolContext();
  const childrenMap = useMemo(() => keyBy(member?.children, 'userId'), [member.children]);

  return (
    <Grid container maxWidth={1500} height={'94.5vh'} overflow={'scroll'}>
      <Grid container justifyContent="center" alignContent={'center'} overflow={'scroll'} mb={30}>
        <UserCard
          user={member.user}
          member={member}
          groupEnrolments={member.groupEnrolments.filter(i => i.userId !== member.userId)}
        />
        {member.user.children.map(c => (
          <UserCard
            key={c.id}
            member={childrenMap[c.id]}
            groupEnrolments={childrenMap[c.id]?.groupEnrolments}
            user={c}
          />
        ))}
      </Grid>
    </Grid>
  );
};
