import { useSchoolContext } from 'components/context/school-context';
import { Dictionary } from 'lodash';
import { Contact } from '../../shared/contact';
import { Carousel } from '../../carousel';
import { CardBase } from 'components/shared/card-base';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { daysBetween } from 'utils/time';
import { Enrolment, Member } from 'hooks/useMember';
import { DateTime } from 'luxon';
import { MemberSelectButton } from 'components/shared/member-select-button';

type Props = {
  memberMap: Dictionary<Member>;
  enrolments: Enrolment[];
};

export const Absence = ({ memberMap, enrolments }: Props) => {
  const { school } = useSchoolContext();

  return (
    <CardBase sx={{ height: 'fit-content', m: 3 }}>
      <Box display={'flex'} p={3} mb={3}>
        <PersonOffIcon sx={{ fontSize: 30, color: school.priColor }} />
        <Typography variant="h3" m={'auto'} ml={2}>
          Absences
        </Typography>
      </Box>
      <Carousel height={74} color="#fffff" variant="text">
        {enrolments
          .map(e => ({
            ...e,
            days: daysBetween(memberMap[e.userId].lastAttendance, DateTime.now().toISO()) - 1,
          }))
          ?.filter(e => e.days >= 14)
          .sort((a, b) => a.days - b.days)
          .map((e, idx) => (
            <MemberSelectButton key={`a${idx}`} userId={e.userId}>
              <Box>
                <Typography ml={4} variant={'h3'}>
                  {`Absent for ${e.days} days`}
                </Typography>
                <Contact user={memberMap[e.userId]?.user} />
              </Box>
            </MemberSelectButton>
          ))}
      </Carousel>
    </CardBase>
  );
};
