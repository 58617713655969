import { Session } from '../../../hooks/useClass';
import { Classes } from './classes';
import { ScrollContainer } from 'components/shared/scroll-container';
import { MemberAlerts } from './member-alerts';

export type Schedule = {
  date: string;
  sessions: Session[];
};
export const Dashboard = () => {
  return (
    <ScrollContainer>
      <Classes />
      <MemberAlerts />
    </ScrollContainer>
  );
};
