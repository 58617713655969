import { Box, Typography } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import { dateRange, dayName, dayTime, friendlyDate, iso, isoDate, toIso } from 'utils/time';
import { Carousel } from '../carousel';
import { ClassCard } from './ClassCard';
import { ClassModal } from 'components/home/timetable/class-modal';
import { Closure, useClosure } from 'hooks/useClosure';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo, useRef } from 'react';
import { Schedule } from '..';
import { useAttendance } from 'hooks/useAttendance';
import { Session, useClass } from 'hooks/useClass';
import { ReactComponent as RestImage } from './../../../../assets/rest.svg';
import { useSchoolContext } from 'components/context/school-context';

export const Classes = () => {
  const { school } = useSchoolContext();

  const start = useRef(toIso(DateTime.now().toISODate()));
  const end = useRef(toIso(DateTime.now().plus({ days: 14 }).toISODate()));

  const { classes, isLoading } = useClass(school.id, start.current, end.current);
  const { attendance } = useAttendance(school.id, start.current);
  const { data: closures } = useClosure(school.id);

  const [now, setNow] = useState(DateTime.now());
  const next = now.hour * 60 + now.minute + 30;
  const later = now.hour * 60 + now.minute + 90;

  const getDecoration = (time: DateTime) => {
    const t = time.hour * 60 + time.minute;
    if (t >= later) return 'LATER';
    if (t >= next) return 'NEXT';
    return 'NOW';
  };

  const [open, setOpen] = useState(false);
  const [currentClass, setCurrentClass] = useState<{ date: string; session: Session }>();
  const date = iso(start.current).toISODate();

  const margin = 0.5;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const startTime = now.minus({ hours: margin }).toISO();

  const closureRange = (closure: Closure) => {
    if (closure.repeating) {
      return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
    }
    return dateRange(isoDate(closure.startDate), isoDate(closure.endDate));
  };
  const closureMap = closures?.map(c => ({
    range: closureRange(c),
    data: c,
    locations: c.locations.map(l => l.id),
    subjects: c.subjects.map(s => s.id),
  }));

  const schedule: Schedule = useMemo(
    () => ({
      date,
      sessions: classes
        .filter(
          c =>
            iso(date).weekNumber % c.frequency === c.week &&
            dayName(c.dateTime) === dayName(date) &&
            iso(c.dateTime).hour >= iso(now.toISO()).hour - margin &&
            isoDate(date) >= isoDate(c.dateTime) &&
            (c?.endDate ? iso(c.endDate).toMillis() >= iso(startTime).toMillis() : true)
        )
        .map(c => ({
          ...c,
          attendance: attendance
            .filter(a => iso(a.date).toISODate() === date && a.classId === c.id && !a.cancelled)
            .map(i => i.userId),
        })),
    }),
    [classes, date, startTime, now, attendance]
  );

  const groupedSessions = useMemo(
    () =>
      schedule.sessions
        ?.sort((a, b) => dayTime(a.dateTime) - dayTime(b.dateTime))
        .reduce(
          (acc, child, index) => {
            !!index && index % 4 == 0 ? acc.push([child]) : acc[acc.length - 1].push(child);
            return acc;
          },
          [[] as Session[]]
        ),
    [schedule]
  );

  const selectedSession = useMemo(
    () => schedule.sessions.find(i => i.id === currentClass?.session.id),
    [currentClass, schedule]
  );

  const handleClick = (session: Session) => {
    setCurrentClass({ date, session });
  };

  return (
    <>
      {currentClass && selectedSession && (
        <ClassModal
          currentClass={currentClass}
          session={selectedSession}
          attendance={attendance}
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrentClass(undefined);
          }}
        />
      )}
      <CardBase sx={{ height: 'fit-content', m: 3 }}>
        <Typography sx={{ m: 3, mb: 0 }} variant="h3">
          {friendlyDate(date).toUpperCase()}
        </Typography>
        {!!schedule.sessions.length ? (
          <Carousel height={320}>
            {groupedSessions.map((sArray, idx) => (
              <Box key={`g${idx}`} sx={{ scrollSnapAlign: 'center' }}>
                {sArray.map(s => (
                  <ClassCard
                    key={s.id}
                    decoration={getDecoration(iso(s.dateTime))}
                    session={s}
                    handleClick={() => {
                      handleClick(s);
                      setOpen(true);
                    }}
                    closure={
                      closureMap?.find(
                        c =>
                          (!c.locations.length || c.locations.includes(s.location.id)) &&
                          (!c.subjects.length || c.subjects.some(sub => s.subject.some(i => i.id === sub))) &&
                          c.range.some(r => (c.data.repeating ? r.includes(date.substring(5)) : r === date))
                      )?.data?.description
                    }
                  />
                ))}
              </Box>
            ))}
          </Carousel>
        ) : (
          <CardBase sx={{ height: 368 }}>
            <Box display={'flex'} width={1} height={1}>
              <Box m={'auto'}>{!isLoading && <RestImage display={'flex'} height={100} fill={school.priColor} />}</Box>
            </Box>
          </CardBase>
        )}
      </CardBase>
    </>
  );
};
