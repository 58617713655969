import { useSchoolContext } from 'components/context/school-context';
import { Dictionary } from 'lodash';
import { Status, statusText } from 'utils/types';
import { Contact } from '../../shared/contact';
import { Carousel } from '../../carousel';
import { CardBase } from 'components/shared/card-base';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Enrolment, Member } from 'hooks/useMember';
import { MemberSelectButton } from 'components/shared/member-select-button';

type Props = {
  memberMap: Dictionary<Member>;
  enrolments: Enrolment[];
};

export const Payments = ({ memberMap, enrolments }: Props) => {
  const { school } = useSchoolContext();

  return (
    <CardBase sx={{ height: 'fit-content', m: 3 }}>
      <Box display={'flex'} p={3} mb={3}>
        <MonetizationOnIcon sx={{ fontSize: 30, color: school.priColor }} />
        <Typography variant="h3" m={'auto'} ml={2}>
          Failed payments
        </Typography>
      </Box>
      <Carousel height={74} color="#fffff" variant="text">
        {enrolments
          ?.filter(e => e.status === Status.PAST_DUE || e.status === Status.UNPAID)
          .map((e, idx) => (
            <MemberSelectButton key={`a${idx}`} userId={e.userId}>
              <Box>
                <Typography ml={4} variant={'h3'}>
                  {`Invoice ${statusText[e.status]}`}
                </Typography>
                <Contact user={memberMap[e.userId]?.user} />
              </Box>
            </MemberSelectButton>
          ))}
      </Carousel>
    </CardBase>
  );
};
