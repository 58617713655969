import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DisplayImage } from 'components/shared/display-image';
import { contrast } from 'Theme';
import { ContactButton } from './contact-button';
import { User } from 'hooks/useUser';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { useCustomTheme } from 'hooks/useCustomTheme';

export const Contact = ({ user, bgColor = '#fffff' }: { user: User; bgColor?: string }) => {
  const { cardWidth } = useCustomTheme();

  return (
    <Box display={'flex'} mt={2} width={cardWidth} justifyContent={'space-between'}>
      <Box display={'flex'} ml={4}>
        <DisplayImage firstName={user.firstName} picture={user.picture} size={36} />
        <Typography color={contrast(bgColor)} m={3} variant="h2">{`${user.firstName}. ${user.lastName
          .substring(0, 1)
          .toUpperCase()}`}</Typography>
      </Box>
      <Box mr={3}>
        <ContactButton action="mailto" destination={user.email}>
          <EmailRoundedIcon color={'primary'} />
        </ContactButton>
        <ContactButton action="tel" destination={user.phone}>
          <LocalPhoneRoundedIcon color={'primary'} />
        </ContactButton>
        <ContactButton action="sms" destination={user.phone}>
          <TextsmsIcon color={'primary'} />
        </ContactButton>
      </Box>
    </Box>
  );
};
