import { Box, Button, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useMembership } from '../../../../hooks/useMemership';
import { AuthWrapper } from 'components/auth-wrapper';
import { MembershipModal } from 'components/modals/membership-modal';
import { MembershipCard } from '../../../shared/membership-card/membership-card';
import { useSchoolContext } from '../../../context/school-context';
import { useQueryState } from 'hooks/useQueryState';
import { sortBy } from 'lodash';
import { ScrollContainer } from 'components/shared/scroll-container';
import { AddButton } from 'components/shared/add-button';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { PageType } from 'utils/types';

export const Memberships: React.FC = () => {
  const {
    school,
    account: { charges_enabled, payouts_enabled },
  } = useSchoolContext();
  const { cardWidth, small } = useCustomTheme();
  const [, setQuery] = useQueryState();
  const [membershipModal, setMembershipModal] = useState(false);
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const accountActive = charges_enabled && payouts_enabled;

  return (
    <>
      <MembershipModal open={membershipModal} onClose={() => setMembershipModal(false)} />
      {useMembershipResponse.data?.length ? (
        <ScrollContainer fullWidth>
          <Box
            sx={{
              overflow: 'scroll',
              display: 'flex',
              scrollSnapType: 'x mandatory',
            }}
            ref={ref}
            pr={50}
            onMouseDown={onMouseDown}
            width={1}
          >
            {sortBy(
              useMembershipResponse.data.filter(i => i.type.includes('membership')),
              m => m.id
            ).map(m => (
              <MembershipCard membership={m} key={m.id} />
            ))}
          </Box>
          <Box mt={3} sx={{ ml: small ? 0 : '-180px' }}>
            <AuthWrapper>
              <AddButton
                sx={{ width: cardWidth }}
                label="Create membership"
                disabled={!accountActive}
                onClick={() => setMembershipModal(true)}
              />
            </AuthWrapper>
          </Box>
        </ScrollContainer>
      ) : (
        <Box m={'auto'}>
          <Typography variant="h2">No active memberships</Typography>
          {!accountActive && (
            <Button variant="text" onClick={() => setQuery({ page: PageType.business_settings, tab: '' })}>
              Configure Stripe to enable memberships
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
