import { Birthdays } from './birthdays';
import { Cancelations } from './cancelations';
import { Payments } from './payments';
import { AuthWrapper } from 'components/auth-wrapper';
import { Box } from '@mui/system';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { useUserEnrolments } from 'hooks/admin/useUserEnrolments';
import { useSchoolContext } from 'components/context/school-context';
import { Absence } from './absence';

export const MemberAlerts = () => {
  const { school } = useSchoolContext();
  const { data: enrolments } = useUserEnrolments(school.id);
  const { data: schoolMembers } = useSchoolMembers(school.id);

  const memberMap = useMemo(() => keyBy(schoolMembers, 'userId'), [schoolMembers]);

  if (!enrolments?.length || !schoolMembers?.length) {
    return <></>;
  }

  return (
    <AuthWrapper>
      <Box sx={{ maxWidth: 800 }}>
        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mb={30}>
          <Birthdays />
          <Cancelations memberMap={memberMap} enrolments={enrolments} />
          <Payments memberMap={memberMap} enrolments={enrolments} />
          <Absence memberMap={memberMap} enrolments={enrolments} />
        </Box>
      </Box>
    </AuthWrapper>
  );
};
