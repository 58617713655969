import { Avatar, Box, Button, ButtonBase, Checkbox, CircularProgress, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useKioskContext } from 'components/context/kiosk-context';
import { dayName, dayTime, duration, friendlyDate, friendlyTime, iso, isoDate } from 'utils/time';
import { Search } from './search.tsx';
import { useEffect, useMemo, useState } from 'react';
import { Schedule } from 'components/home/timetable';
import { DateTime } from 'luxon';
import { contrast } from '../../Theme';
import { Session, useKioskSessions } from 'hooks/useKioskSessions';
import { KioskUser } from 'hooks/useKioskUsers';
import { DisplayImage } from 'components/shared/display-image';
import { useKioskAttendance } from 'hooks/useKioskAttendance';
import ErrorIcon from '@mui/icons-material/Error';
import success from '../../assets/success.mp3';
import error from '../../assets/error.mp3';
import { sortBy } from 'lodash';

export type ResponseType = undefined | 'Checked in' | 'Error checking in - plase check membership type';

export const Kiosk = () => {
  const { kiosk, priColor } = useKioskContext();
  const { sessions } = useKioskSessions(kiosk);
  const [checkedIn, setCheckedIn] = useState<Record<string, string>>({});
  const [selectedUser, setSelectedUser] = useState<KioskUser>();
  const [skipInterval, setSkipInterval] = useState(false);
  const [now, setNow] = useState(DateTime.now());
  const margin = 4;
  const startTime = now.minus({ hours: margin }).toISO();

  const day = DateTime.now().toISODate();

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleResponse = (response: { classId: number; checkinStatus: string }) => {
    setCheckedIn(i => ({ ...i, [response['classId']]: response.checkinStatus }));
    new Audio(response.checkinStatus === 'success' ? success : error).play();
  };

  const handleClear = () => {
    setSelectedUser(undefined);
    setCheckedIn({});
  };

  const { create: book } = useKioskAttendance(kiosk, handleResponse);

  const handleBook = (session: Session) => {
    if (selectedUser) {
      setCheckedIn(i => ({ ...i, [session.id]: 'loading' }));
      book({
        userId: selectedUser.id,
        classId: session.id,
        date: `${now.toISODate()}T${iso(session.dateTime).toISOTime()}`,
      });
      setSkipInterval(true);
    }
  };

  const handleSelect = (u: KioskUser) => {
    setSelectedUser(u);
    setSkipInterval(true);
  };

  const next = now.hour * 60 + now.minute + 30;
  const later = now.hour * 60 + now.minute + 90;

  const getDecoration = (time: DateTime) => {
    const t = time.hour * 60 + time.minute;
    if (t >= later) return 'LATER';
    if (t >= next) return 'NEXT';
    return 'NOW';
  };

  const schedule: Schedule = useMemo(
    () => ({
      date: day,
      sessions: sessions.filter(
        c =>
          iso(day).weekNumber % c.frequency === c.week &&
          dayName(c.dateTime) === dayName(day) &&
          iso(c.dateTime).hour >= iso(now.toISO()).hour - 0.5 &&
          iso(c.dateTime).hour <= iso(now.toISO()).hour + margin &&
          isoDate(day) >= isoDate(c.dateTime) &&
          (c?.endDate ? iso(c.endDate).toMillis() >= iso(startTime).toMillis() : true)
      ),
    }),
    [sessions, day, startTime, now]
  );

  useEffect(() => {
    if (!skipInterval) {
      setSelectedUser(undefined);
      setCheckedIn({});
    }
  }, [schedule]);

  useEffect(() => {
    if (skipInterval) {
      setSkipInterval(false);
    }
  }, [schedule]);

  return (
    <Box>
      <Box display={'flex'} flexWrap={'wrap'} p={4} height={180} justifyContent={'space-between'}>
        <Avatar
          src={kiosk.school.logo}
          sx={{ width: '60px', height: '60px', boxShadow: `1px 2px 2px ${grey[500]}`, mr: 3 }}
        />
        <Box mx={'auto'} mt={4} display={'flex'}>
          <Box>
            <Typography variant="h1" color={grey[800]}>
              {friendlyDate(schedule.date)}
            </Typography>
            {selectedUser ? (
              <Box display="flex" justifyContent={'center'}>
                <Box textAlign={'center'}>
                  <Box mt={6} display="flex">
                    <DisplayImage
                      firstName={selectedUser.firstName}
                      picture={selectedUser.picture}
                      size={50}
                      sx={{ mr: 4 }}
                    />
                    <Typography m="auto" variant="h1" fontWeight={400}>
                      {`${selectedUser.firstName} ${selectedUser.lastName}`}
                    </Typography>
                  </Box>
                  <Button sx={{ mt: 3 }} variant="contained" onClick={handleClear}>
                    Done
                  </Button>
                </Box>
              </Box>
            ) : (
              <Search onClick={u => handleSelect(u)} onChange={() => setSelectedUser(undefined)} />
            )}
          </Box>
        </Box>
        <Box width={60} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box px={4} width={1} maxWidth={700}>
          <Box>
            {!schedule.sessions.length && (
              <Typography variant="h2" color={grey[800]} mt={12}>
                {'no current classes'}
              </Typography>
            )}
            <Box mt={6} overflow={'scroll'} height={1} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
              {sortBy(schedule.sessions, i => dayTime(i.dateTime)).map(i => (
                <ButtonBase
                  key={i.id}
                  onClick={() => handleBook(i)}
                  sx={{
                    width: 1,
                    py: 1,
                    pr: 4,
                    bgcolor: 'white',
                    mb: 0.5,
                  }}
                >
                  <Box display={'flex'} width={300} maxWidth={300} flexWrap={'wrap'} sx={{ height: 60 }}>
                    <Box sx={{ mt: 2 }}>
                      <Box
                        bgcolor={priColor}
                        sx={{
                          display: 'flex',
                          width: 60,
                          height: 22,
                          borderRadius: '0 20px 20px 0',
                          p: 1,
                        }}
                      >
                        <Typography variant="h3" sx={{ color: contrast(priColor) }}>
                          {getDecoration(iso(i.dateTime))}
                        </Typography>
                      </Box>
                    </Box>
                    <Box m={'auto'} ml={6} key={i.id} display={'flex'}>
                      <Box textAlign={'start'}>
                        <Typography variant="h3" sx={{}} mr={1}>
                          {friendlyTime(i?.dateTime)}
                        </Typography>
                        <Typography variant="h5">{duration(i.duration)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display={'flex'} width={1} justifyContent={'space-between'}>
                    <Box
                      sx={{
                        ml: 6,
                        my: 'auto',
                        minWidth: 180,
                        textWrap: 'wrap',
                      }}
                      key={i.id}
                    >
                      <Typography variant="h2" textAlign={'left'}>
                        {i.subject.map(s => s.name).join(', ')}
                      </Typography>
                    </Box>
                    {checkedIn?.[i.id] === 'loading' ? (
                      <Box ml={6} my={'auto'} textAlign={'end'}>
                        <CircularProgress sx={{ mt: 1, mr: 1 }} size={20} />
                      </Box>
                    ) : (
                      <>
                        {checkedIn?.[i.id] === 'error' ? (
                          <Box ml={6} my={'auto'} textAlign={'end'}>
                            <ErrorIcon />
                          </Box>
                        ) : (
                          <Box ml={6} my={'auto'} textAlign={'end'}>
                            <Checkbox disabled={!selectedUser} checked={checkedIn?.[i.id] === 'success'} />
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </ButtonBase>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
