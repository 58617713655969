import { Grid, Card, Button, Box, IconButton } from '@mui/material';
import { useMutate } from 'hooks/useMutate';
import { isActive } from 'utils/types';
import { grey } from '@mui/material/colors';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Enrolment } from 'hooks/useMember';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { EnrolmentPriceModal } from 'components/modals/enrolment-price-modal';
import { AuthWrapper } from 'components/auth-wrapper';
import { Subjects } from 'components/shared/enrolment-card/subjects';
import { Details } from 'components/shared/enrolment-card/details';
import { GroupUsers } from './group-users';
import { User } from 'hooks/useUser';

type MembershipProps = {
  user: User;
  enrolment: Enrolment;
  button: JSX.Element;
};

export const EnrolmentCard: React.FC<MembershipProps> = ({ user, enrolment, button }) => {
  const { small } = useCustomTheme();
  const [edit, setEdit] = useState(false);
  const { membership } = enrolment;

  const groupOwner = !(enrolment.membership.type.includes('group') && enrolment.userId !== user.id);

  const { create } = useMutate('payment/portal', {
    headers: { href: location.href },
    onSuccess: (resp: string) => (window.location.href = resp),
  });

  return (
    <>
      {edit && <EnrolmentPriceModal open={edit} onClose={() => setEdit(false)} enrolment={enrolment} />}
      <Grid sx={{ ...(small && { scrollSnapAlign: 'center', scrollSnapStop: 'always' }) }}>
        <Card style={{ display: 'flex', padding: 16, boxSizing: 'border-box' }}>
          <Grid container height={1} direction={'column'} justifyContent={'space-between'}>
            <Grid container flexGrow={1} direction={'column'} justifyContent={'space-between'}>
              <Details enrolment={enrolment} groupOwner={groupOwner} membership={membership}>
                <AuthWrapper>
                  <Box mr={-3} mt={-3}>
                    <IconButton size="small" onClick={() => setEdit(true)}>
                      <EditIcon sx={{ height: 18, width: 18 }} />
                    </IconButton>
                  </Box>
                </AuthWrapper>
              </Details>
              <Subjects membership={membership} />
              {!!enrolment.membership.type.includes('group') && groupOwner && (
                <GroupUsers user={user} enrolment={enrolment} />
              )}
              <Box>
                <Box
                  display={'flex'}
                  pt={2}
                  sx={{ borderTop: `solid 1px ${grey[300]}` }}
                  justifyContent={'space-between'}
                >
                  <Box>
                    {membership.type.includes('membership') && isActive(enrolment.status) && groupOwner && (
                      <Box sx={{ width: 64, textAlign: 'center' }}>
                        <>{button}</>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {groupOwner && (
                      <Button variant="outlined" onClick={() => create({ userId: user.id })}>
                        Billing details
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Box textAlign={'center'}></Box>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
