import { useQueryState } from 'hooks/useQueryState';
import { Box } from '@mui/material';
import { PageType, Tabs } from 'utils/types';
import { Memberships } from 'components/admin/pricing/memberships';
import { AuthWrapper } from 'components/auth-wrapper';
import { Coupons } from './coupons';
import { ClassPasses } from './passes';

export const Pricing = () => {
  const [query] = useQueryState();
  const currentTab = query[`${query.page} tab`];
  const tab = (n: number): boolean => currentTab === Tabs[PageType.pricing][n];

  return (
    <AuthWrapper>
      <Box display={'flex'} width={1} justifyContent={'center'} height={'94.5vh'} overflow={'scroll'}>
        {tab(0) && <Memberships />}
        {tab(1) && <ClassPasses />}
        {tab(2) && <Coupons />}
      </Box>
    </AuthWrapper>
  );
};
