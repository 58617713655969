import { Box, Button, Card, Checkbox, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SubscriptionParams } from 'utils/types';

type Props = {
  open: boolean;
  onClose: (arg?: SubscriptionParams) => void;
  type: 'pause' | 'cancel';
};

export const UpdateSubscriptionModal = ({ open, onClose, type }: Props) => {
  const { cardWidth } = useCustomTheme();
  const currentDate = DateTime.now().plus({ days: 1 });
  const [dateTime, setDateTime] = useState<DateTime>(currentDate);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const text = type === 'pause' ? `Date to resume billing` : `Cancel date`;

  const handleCancel = () => {
    onClose({
      ...(cancelAtPeriodEnd && { cancel_at_period_end: cancelAtPeriodEnd }),
      cancel_at: cancelAtPeriodEnd ? undefined : Math.round(dateTime.toSeconds()),
    });
  };

  const handlePause = () => {
    onClose({ pause_collection: { behavior: 'keep_as_draft', resumes_at: Math.round(dateTime.toSeconds()) } });
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Card sx={{ m: 'auto', width: cardWidth }}>
        <Box width={1} p={4}>
          <Typography variant="h3" mb={6}>
            {text}
          </Typography>
          {type === 'cancel' ? (
            <>
              <Box sx={{ mb: 4, borderBottom: `solid 1px ${grey[300]}` }}>
                <Box display="flex">
                  <Checkbox checked={cancelAtPeriodEnd} onChange={e => setCancelAtPeriodEnd(e.currentTarget.checked)} />
                  <Typography my="auto" mr={2} ml={2}>
                    Cancel before next invoice?
                  </Typography>
                </Box>
                <Box mx={'auto'} textAlign={'center'} bgcolor={'white'} width={30}>
                  <Typography mt={4} mb={-2} variant={'h3'}>
                    OR
                  </Typography>
                </Box>
              </Box>
              <DatePicker
                disabled={cancelAtPeriodEnd}
                disablePast
                minDate={currentDate}
                label={'Future date'}
                format={'dd/MM/yyyy'}
                onChange={d => {
                  if (d && d.toMillis() > currentDate.toMillis()) setDateTime(d as DateTime);
                }}
                value={dateTime}
              />
              <Box textAlign={'end'} mt={6}>
                <Button onClick={handleCancel} variant="contained">
                  Confirm
                </Button>
              </Box>
            </>
          ) : (
            <>
              <DatePicker
                disablePast
                label={'Resume date'}
                format={'dd/MM/yyyy'}
                onChange={d => {
                  if (d && d.toMillis() > currentDate.toMillis()) setDateTime(d as DateTime);
                }}
                value={dateTime}
              />
              <Box textAlign={'end'} mt={6}>
                <Button onClick={handlePause} variant="contained">
                  Confirm
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Card>
    </CustomModal>
  );
};
