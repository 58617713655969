import { Box, MenuItem, Typography } from '@mui/material';
import { Session } from 'hooks/useClass';
import { duration, friendlyTime } from '../../../../utils/time';
import { useUserContext } from 'components/context/user-context';
import PlaceIcon from '@mui/icons-material/Place';
import { useSchoolContext } from 'components/context/school-context';
import { THEME } from 'Theme';
import { useCustomTheme } from 'hooks/useCustomTheme';

type Props = {
  session: Session;
  handleClick: (c: Session) => void;
  closure?: string;
  decoration: string;
};

export const ClassCard = ({ session: c, handleClick, closure, decoration }: Props) => {
  const { school } = useSchoolContext();
  const { users } = useUserContext();
  const { cardWidth } = useCustomTheme();

  const registeredUsers = users.filter(i => c.attendance.includes(i.id));

  return (
    <Box width={cardWidth} sx={{ display: 'flex', mt: 4.5 }} key={c.id}>
      <MenuItem disabled={!!closure} sx={{ pl: 0, height: 60, width: '100%' }} onClick={() => handleClick(c)}>
        {closure && (
          <Box display={'flex'} height={1} width={1} position={'absolute'} sx={{ backgroundColor: '#e9e9e9d2' }}>
            <Typography variant="h2" margin={'auto'}>
              {closure}
            </Typography>
          </Box>
        )}
        <Box display={'flex'} width={1}>
          <Box mr={3} ml={-2}>
            <Box
              bgcolor={school.priColor}
              sx={{ display: 'flex', mt: 2, width: 70, height: 24, borderRadius: '0 20px 20px 0', p: 1 }}
            >
              <Typography variant="h3" sx={{ m: 'auto', color: THEME.palette.getContrastText(school.priColor) }}>
                {decoration}
              </Typography>
            </Box>
          </Box>
          <Box ml={2} width={'80%'} display={'flex'}>
            <Box sx={{ height: 70, pt: 3 }} width={1}>
              <Box display={'flex'}>
                <Box width={80} textAlign={'start'}>
                  <Typography variant="h3" sx={{}} mr={1}>
                    {friendlyTime(c?.dateTime)}
                  </Typography>
                  <Typography variant="h5">{duration(c.duration)}</Typography>
                </Box>
                <Box textAlign={'start'} width={'calc(100% - 100px)'}>
                  <Typography variant="h3" overflow={'hidden'} textOverflow={'ellipsis'}>
                    {`${c.subject.map(i => i.name).join(', ')}`}
                  </Typography>
                  <Box display={'flex'}>
                    <PlaceIcon sx={{ ml: -1, fontSize: 18, color: c.location.color }} />
                    <Typography variant="h5">{c.location.description}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box display={'flex'}></Box>
            </Box>
            {registeredUsers.length > 0 && (
              <Box
                position={'absolute'}
                sx={{ right: 22, width: 75, height: 32, borderRadius: 4, mt: 4.5, bgcolor: school.priColor }}
              >
                <Typography variant="h1" color={'white'}>
                  &#10003;
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </MenuItem>
    </Box>
  );
};
