import * as React from 'react';
import { Box } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useRef } from 'react';
import { contrast } from 'Theme';

type Props = {
  height: number;
  children: JSX.Element[];
  variant?: 'text' | 'dots' | 'progress';
  color?: string;
};

export function Carousel({ height, children, color, variant = 'dots' }: Props) {
  const ref = useRef<HTMLElement>(null);

  const scrollRef = useRef(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = React.useMemo(() => children.length, [children]);

  const handleNext = () => {
    if (ref?.current) {
      ref.current.scrollTo({ left: scrollRef.current + ref.current.scrollWidth / maxSteps });
    }
  };

  const handleBack = () => {
    if (ref?.current) {
      ref.current.scrollTo({ left: scrollRef.current + -ref.current.scrollWidth / maxSteps });
    }
  };

  return (
    <Box width={1}>
      <Box
        ref={ref}
        onScroll={e => {
          scrollRef.current = e.currentTarget.scrollLeft;
          setActiveStep(+(e.currentTarget.scrollLeft / (e.currentTarget.scrollWidth / maxSteps)).toFixed(0));
        }}
        flexGrow={'initial'}
        sx={{
          overflow: 'scroll',
          display: 'flex',
          height: height,
          scrollSnapType: 'x mandatory',
          scrollBehavior: 'smooth',
          scrollSnapMargin: '10px',
        }}
      >
        {children}
      </Box>
      {maxSteps > 1 ? (
        <MobileStepper
          sx={{ bgcolor: 'transparent', height: 44 }}
          key={activeStep}
          variant={variant}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              sx={{ color: color ? contrast(color) : 'primary' }}
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {<KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ color: color ? contrast(color) : 'primary' }}
            >
              {<KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      ) : (
        <Box height={44}></Box>
      )}
    </Box>
  );
}
