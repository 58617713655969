import { Box, Typography, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useMembership } from '../../../../hooks/useMemership';
import { AuthWrapper } from 'components/auth-wrapper';
import { ClassPassModal } from '../../../modals/class-pass-modal/index';
import { useSchoolContext } from '../../../context/school-context';
import { useQueryState } from 'hooks/useQueryState';
import { PageType } from 'utils/types';
import { sortBy } from 'lodash';
import { MembershipCard } from 'components/shared/membership-card/membership-card';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { AddButton } from 'components/shared/add-button';
import { ScrollContainer } from 'components/shared/scroll-container';

export const ClassPasses: React.FC = () => {
  const {
    school,
    account: { charges_enabled, payouts_enabled },
  } = useSchoolContext();
  const [classPassModal, setClassPassModal] = useState(false);
  const { cardWidth, small } = useCustomTheme();
  const [, setQuery] = useQueryState();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const accountActive = charges_enabled && payouts_enabled;

  return (
    <>
      <ClassPassModal open={classPassModal} onClose={() => setClassPassModal(false)} />
      {useMembershipResponse.data?.length ? (
        <ScrollContainer fullWidth>
          <Box
            sx={{
              overflow: 'scroll',
              display: 'flex',
              scrollSnapType: 'x mandatory',
            }}
            ref={ref}
            pr={50}
            onMouseDown={onMouseDown}
          >
            {sortBy(
              useMembershipResponse.data.filter(i => i.type === 'class_pass'),
              m => m.id
            ).map(m => (
              <MembershipCard membership={m} key={m.id} />
            ))}
          </Box>
          <Box mt={3} sx={{ ml: small ? 0 : '-180px' }}>
            <AuthWrapper>
              <AddButton
                sx={{ width: cardWidth }}
                label="Create class pass"
                disabled={!accountActive}
                onClick={() => setClassPassModal(true)}
              />
            </AuthWrapper>
          </Box>
        </ScrollContainer>
      ) : (
        <Box m={'auto'}>
          <Typography variant="h2">No active class passes</Typography>
          {!accountActive && (
            <Button variant="text" onClick={() => setQuery({ page: PageType.business_settings, tab: '' })}>
              Configure Stripe to enable class passes
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
