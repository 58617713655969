import { Box, Switch, Typography } from '@mui/material';
import { CardBase } from 'components/shared/card-base';
import { DateTime } from 'luxon';
import background from './../../../../../assets/birthday-bg.svg';
import { useSchoolContext } from 'components/context/school-context';
import { Carousel } from '../../carousel';
import CakeIcon from '@mui/icons-material/Cake';

import { contrast } from 'Theme';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { friendlyDate } from 'utils/time';
import { useState } from 'react';
import { Contact } from '../../shared/contact';
import { MemberSelectButton } from 'components/shared/member-select-button';

export const Birthdays = () => {
  const { school } = useSchoolContext();
  const { data: members } = useSchoolMembers(school.id);
  const [window, setWindow] = useState(7);

  if (!members?.length) return null;

  const now = DateTime.now();

  const toSequenceStamp = (date: string) => {
    const [m, d] = date.split('-');
    return +`${+m % now.month}${d}`;
  };

  const range = new Array(window).fill('').map((_, i) => now.plus({ days: i }).toFormat('MM-dd'));

  const birthdays = members
    .filter(member => range.includes(member.user.birthday?.substring(5, 10) || ''))
    .map(m => ({ user: m.user, date: m.user.birthday?.substring(5, 10) || '' }));

  const getText = (date?: string) => {
    if (!date) return '';
    if (range[0].includes(date)) {
      return 'Today';
    } else if (range[1].includes(date)) {
      return 'Tomorrow';
    } else {
      return friendlyDate(`${DateTime.now().year}${date}`);
    }
  };

  return (
    <CardBase sx={{ height: 'fit-content', m: 3 }}>
      <Box bgcolor={`${school.priColor}`}>
        <Box
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        >
          <Box mb={1} color={contrast(school.priColor)} display={'flex'} p={3} justifyContent={'space-between'}>
            <Box display="flex">
              <CakeIcon sx={{ fontSize: 30 }} />
              <Typography variant="h3" m={'auto'} ml={2}>
                {`Birthdays`}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography m={'auto'}>{`next ${window} days`}</Typography>
              <Switch color="secondary" onChange={e => setWindow(e.currentTarget.checked ? 30 : 7)}></Switch>
            </Box>
          </Box>
          <Carousel height={74} color={school.priColor} variant="text">
            {birthdays
              .sort((a, b) => toSequenceStamp(a.date) - toSequenceStamp(b.date))
              .map(({ user, date }, idx) => (
                <MemberSelectButton key={`b${idx}`} userId={user.id}>
                  <Box>
                    <Typography ml={4} variant={'h3'} color={contrast(school.priColor)}>
                      {getText(date)}
                    </Typography>
                    <Contact user={user} bgColor={school.priColor} />
                  </Box>
                </MemberSelectButton>
              ))}
          </Carousel>
        </Box>
      </Box>
    </CardBase>
  );
};
